<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="售后单号" prop="returnSn">
                <el-input v-model="query.returnSn" placeholder="请输入售后单号" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="销售单号" prop="orderSn" >
                <el-input v-model="query.orderSn" placeholder="请输入销售单号" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="售后类型" prop="type" >
                <el-select v-model="query.type" placeholder="请选择售后类型" clearable size="small">
                    <el-option v-for="dict in returnTypeList" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="售后状态" prop="status" >
                <el-select v-model="query.status" placeholder="请选择售后状态" clearable size="small">
                    <el-option v-for="dict in returnStatusList" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item label="申请日期" >
                <el-date-picker
                    v-model="dateList"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    @change="selectDate"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="售后单号" align="center" prop="returnSn" />
            <el-table-column label="订单号" align="center" prop="orderSn" />
            <el-table-column prop="productList" width="520">
                <template #header>
                    <el-row class="order-row-item order-border-none">
                        <el-col :span="4">产品图</el-col>
                        <el-col :span="6">产品名称</el-col>
                        <el-col :span="6">详情/规格</el-col>
                        <el-col :span="4">售后数量</el-col>
                    </el-row>
                </template>
                <template #default="scope">
                    <div style="flex:1;display:flex;flex-direction: column;">
                        <div v-for="(order, index) in scope.row.productList" :key="order.id" style="flex: 1;height:100%">
                            <el-row class="order-row-item" style="height:100%">
                                <el-col :span="4">
                                    <img :src="order.skuPic" width="80px" alt="image error">
                                </el-col>
                                <el-col :span="6">{{ order.spuName }}</el-col>
                                <el-col :span="6">{{ order.skuName }}</el-col>
                                <el-col :span="4">{{ order.skuNum }}</el-col>
                            </el-row>
                            <el-divider
                                style="margin: 0 -8px;width:calc(100% + 16px);border-color:var(--el-border-color-light)"
                                v-if="index < scope.row.productList.length - 1" />
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="收货人" align="center" prop="returnName" />
            <el-table-column label="手机号" align="center" prop="returnPhone" />
            <el-table-column label="收货地址" align="center" prop="province" width="180">
                <template #default="scope">
                    <div>{{ scope.row.province }}-{{ scope.row.city }}-{{ scope.row.region }}</div>
                    <div>{{ scope.row.detailAddress }}</div>
                </template>
            </el-table-column>
            <el-table-column label="退款金额" align="center" prop="money" >
                <template #default="scope">
                    <span style="font-size: 18px; color: red; font-weight: bold;">￥{{ (scope.row.money).toFixed(2) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="售后类型" align="center" prop="type">
                <template slot-scope="scope">
                    <el-tag type="success" >{{ returnTypeDict[scope.row.type] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="售后状态" align="center" prop="status">
                <template slot-scope="scope">
                    <el-tag :type="returnStatusDict[scope.row.status].color" >{{ returnStatusDict[scope.row.status].label }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="申请时间" align="center" prop="createTime" />
            <el-table-column label="审批时间" align="center" prop="approvalTime" />
            <el-table-column label="完成时间" align="center" prop="successTime" />


            <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit"
                        @click="approveFunc(scope.row, 1)" v-hasPermi="['oms:afterSale:approve']">同意</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete"
                        @click="approveFunc(scope.row, -1)" v-hasPermi="['oms:afterSale:approve']">驳回</el-button>                    

                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'OrderList',
    data() {
        return {
            // 列表
            list: [],
            // 选择日期
            dateList: [],
            // 类型
            returnTypeDict: {
                0: '重新配送',
                1: '退款',
                2: '换货',
                3: '退货退款',
            },
            returnTypeList: [
                {value: 0, label: '重新配送'},
                {value: 1, label: '退款'},
                {value: 2, label: '换货'},
                {value: 3, label: '退货退款'},
            ],
            // 状态
            returnStatusDict: {
                '-1': { color: 'danger', label: '驳回' },
                '0': { color: 'warning', label: '申请中' },
                '1': { color: '', label: '售后中' },
                '2': { color: 'success', label: '售后完成' },
            },
            returnStatusList: [
                {value: 0, label: '申请中'},
                {value: 1, label: '售后中'},
                {value: 2, label: '售后完成'},
                {value: -1, label: '驳回'},
            ],
            // 总条数
            total: 0,
            // 遮罩层
            loading: false,
            // 查询
            query: {
                current: 1,
                size: 10,
                type: null,
                status: null,
                returnSn: '',
                orderSn: '',
                startDate: '',
                endDate: '',
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.afterSaleList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        /** 选择日期 */
        selectDate(e) {
            console.log(e);
            if (e != null && e.length > 0) {
                this.query.current = 1
                this.query.startDate = e[0]
                this.query.endDate = e[1]
                this.getList()
            }else {
                this.query.current = 1
                this.query.startDate = ''
                this.query.endDate = ''
                this.getList()
            }
        },
        // 重置
        reset() {
            this.query = {
                current: 1,
                size: 10,
                type: null,
                status: null,
                returnSn: '',
                orderSn: '',
                startDate: '',
                endDate: '',
            },
            this.dateList = [],            
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 审批 */
        approveFunc(data, type) {
            this.$confirm('是否' + (type == 1?'通过':'驳回') + data.nickName + '的售后申请？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.afterApprove({
                    id: data.id,
                    status: type
                }).then(res => {
                    this.$message.success("审批成功")
                    this.getList()
                }).catch(() => {})
            })
        }
    }
}

</script>